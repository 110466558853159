.NewOrgEmployee form {
  padding-bottom: 15px;

}

.NewOrgEmployee form textarea {
  height: 200px;

}
.recList {
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 16px;
}
