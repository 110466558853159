.NewObservation form {
  padding-bottom: 50px;

}

.NewObservation form textarea {
  height: 200px;

}
.NewObservation .picgrid {

  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  overflow:hidden;
  background-color: #0000;
  height: 150px;

  /*height: 100px;*/
}
.NewObservation .picgrid img {
  object-fit: cover;
width: 100%;

}
.NewObservation .card {
  background-color: #f9faf9;


}
.NewObservation .recList img {
  object-fit: cover;
width: 150px;
height: 150px;

}
.ibcontainer {
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
  padding: 8px;
}

.ibcontainer .btn {
  position: absolute;
  top: 1;
  right: 1;
   outline: none;
   border: none;
 cursor: pointer;


}
.ibcontainer img {
  border-radius:15px;
}


.NewObservation .gridList {
  flex-wrap: nowrap;
  transform: translateZ(0);
  width: 100%;



}
.NewObservation .title {
  color: #555;
  background-color: #fff8;
}

.NewObservation .recList {
  background-color: #f9faf9;
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
  padding: 8px;
}
.NewObservation .generalHorizontalLayout {
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
}
.NewObservation .chip {
  margin: 8px;
}
.NewObservation .right_align {
  float:right;
}
