.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: lighter;

}
.orgBox {
	font-weight: lighter;
	display: inline-block;


	font-size: 10px;

/*
  background: #999;
  */

	color: #33ff33;
	height: 28px;
	padding-left: 4px;
	padding-right: 4px;
  padding-top: 4px;
	margin: 12px;
/*
	border: 1px solid #fff;
    border-radius: 5px;
*/

}
html {
  font-size: 16px;
}
