.Organisation form {
  padding-bottom: 15px;
}

.Organisation form textarea {
  height: 300px;
  font-size: 24px;
}

.Organisation .users h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  font-size: 24px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Organisation .users p {
  color: #666;
  line-height: 2.5;
}