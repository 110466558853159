.PropertyKeys form {
  padding-bottom: 15px;
}

.PropertyKeys form textarea {
  height: 300px;
  font-size: 24px;
}

.PropertyKeys .versions h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  font-size: 24px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PropertyKeys .versions p {
  color: #666;
  line-height: 2;
  font-size: 20px;
}
.PropertyKeys .aaaaa span {
  color: #f00;
  line-height: 2.5;
}

.PropertyKeysHeader {
  color: #f00;
  line-height: 2.5;
}
.dialog_overflow {
  overflow: visible;
}


#propertyKeysHeaderBox {
    border-radius: 8px;
    border: 2px solid #73AD21;

    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;

}
.header {
  text-align: left;
    border-radius: 8px;
    border: 2px solid #73AD21;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
}
.header img {
  float: left;
  padding-top: 5px;
}
.clear {
  clear: both;
}




div.qrcodes {

  border: 0px solid #4CAF50;
}


table, th, td, span, p {
   border-collapse: collapse;
 padding:0px;
 margin: 0px;


 }
 td.v {

   padding:0mm 0mm 0mm 0mm;

 }
 img.ttt{
   width: 25mm;
     /*max-width: 100%;*/
     /*height: auto;*/
     /*max-height: 100%;*/
     /*max-height: calc(100% - 30px);*/
     /*margin: 0 auto;*/
     vertical-align: middle;
  /*width:25mm;*/
  padding: 0mm 2mm 0mm 0mm;
  /*border: 1px dashed grey;*/
  margin: 0mm -6mm 0mm -8mm;
  /*margin: calc((105px - 40px)/2) calc((40px - 105px)/2);*/

 }
 img.uuu{
   width: 30mm;
  /*padding: 16mm 16mm 16mm 16mm;
  border: 1px dashed grey;*/
  margin: 0mm 4mm 4mm 0mm;
  /*margin: calc((105px - 40px)/2) calc((40px - 105px)/2);*/

 }
 td.x_blank {
    border: 1px solid green;
   padding:0px;

 }
 td.x {
    border: 1px dashed grey;
   padding:0px;

 }
 tr.x_properties {
    border: 1px dashed grey;
   padding:50px;
   margin-top: 32px;

 }
td.y {
align:right;
  padding:2mm 2mm 2mm 0mm;

}
td.z {

border: 1px solid #eee;
width: 0px;
}

td.location {
padding: 5mm 0mm 0mm 0mm;
}

tr.keepTogether {
  /*page-break-inside: avoid;
  page-break-before: always;*/
}

table.a {
  table-layout: auto;
  width: 195mm;
  margin: 0 auto;


}
span.qkeyz {
  writing-mode: vertical-lr;
  /* Safari */
  -webkit-transform: rotate(270deg);

  /* Firefox */
  -moz-transform: rotate(270deg);

  /* IE */
  -ms-transform: rotate(270deg);

  /* Opera */
  -o-transform: rotate(270deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

span.keyid {
  writing-mode: vertical-lr;
  /* Safari */
  -webkit-transform: rotate(180deg);

  /* Firefox */
  -moz-transform: rotate(180deg);

  /* IE */
  -ms-transform: rotate(180deg);

  /* Opera */
  -o-transform: rotate(180deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}


table.b {
  table-layout: fixed;
  width:100%;
 }

table.c {
  table-layout: auto;
  width: 100%;
}


p.i {
  font: 14px arial, sans-serif;
  margin: 0;
  padding: 0;
  color: white !important;
  -webkit-print-color-adjust: exact;
}

p.g {
  font: 12px arial, sans-serif;
  margin: 0;
  padding: 0;
}
p.h {
  font: 10px arial, sans-serif;
white-space: nowrap;
padding: 0mm 0.5mm 0mm 0mm;
}
p.e {
  font: 14px arial, sans-serif;
}
p.f {
  font: 44px arial, sans-serif;
  padding: 16px;
}
