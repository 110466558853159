.NewVisitor form {
  padding-bottom: 15px;

}

.NewVisitor form textarea {
  height: 200px;

}

.NewVisitor .input {
  display: none;
}
