.PropertyLog form {
  padding-bottom: 15px;
}

.PropertyLog form textarea {
  height: 300px;
  font-size: 24px;
}

.PropertyLog .versions h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  font-size: 24px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PropertyLog .versions p {
  color: #666;
  line-height: 2;
  font-size: 20px;
}
.PropertyLog .aaaaa span {
  color: #f00;
  line-height: 2.5;
}

.PropertyLogHeader {
  color: #f00;
  line-height: 2.5;
}


#PropertyLogHeaderBox {
    border-radius: 8px;
    border: 2px solid #73AD21;

    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;

}
