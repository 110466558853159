
div.landerButton {
  display: inline-block;
  vertical-align: top;
  background-color: #00000011;
  margin: 10px;
  text-align:center;
  width:250px;
  height:200px;
  cursor: pointer;
  padding:20px;
}
div.landerButton img {
  height:50px;
  margin-bottom: 20px;
}
p {
  font-family:'HelveticaNeue-Heavy', 'Arial';
}

p.desc {
  font-size:9pt;
  color: #ccc;
}
p.title {
  font-size:11pt;
  color: #777;
}
div.landerButton:hover p.title{
	color: #c64000;
}

.Home .paper {


    padding:8px 8px 8px 8px;



}

.Home .options {
  height: calc(100vh - 262px);
/*background-image:url('../images/options.png');
background-repeat:repeat;*/
  /*background-color: #efefef;
background: url("/images/ftc_logo.png");*/
}
.Home .dialog {
  height: 500px;

}
.Home .lander {
  padding: 80px 0;
  text-align: center;
  /*background-image:url('/images/ftc_logo.png');*/
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}
.Home .options h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Home .options p {
  color: #666;
}
