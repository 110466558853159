.Observations .lander {
  padding: 80px 0;
  text-align: center;
}

.Observations .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Observations .lander p {
  color: #999;
}
.Observations .items h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Observations .items p {
  color: #666;
}
.breadcrumb > li + li:before {
    color: #ccc;
    content: "> ";
    padding: 0 5px;
}
