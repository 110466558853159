.NewProperty form {
  padding-bottom: 15px;

}

.NewProperty form textarea {
  height: 200px;

}
.NewProperty .label_as_button {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #ddd;
  cursor: pointer;
}

.NewProperty .label_as_button:hover {
  background-color: #efefef;

}
