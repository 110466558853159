.NewJob form {
  padding-bottom: 50px;

}

.NewJob form textarea {
  height: 200px;

}
.NewJob .picgrid {

  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  overflow:hidden;
  background-color: #0000;
  height: 150px;

  /*height: 100px;*/
}
.NewJob .picgrid img {
  object-fit: cover;
width: 100%;

}
.NewJob .card {
  background-color: #f9faf9;


}
.NewJob .recList img {
  object-fit: cover;
width: 150px;
height: 150px;

}
.ibcontainer {
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
  padding: 8px;
}

.ibcontainer .btn {
  position: absolute;
  top: 1;
  right: 1;
   outline: none;
   border: none;
 cursor: pointer;


}
.ibcontainer img {
  border-radius:15px;
}


.NewJob .gridList {
  flex-wrap: nowrap;
  transform: translateZ(0);
  width: 100%;



}
.NewJob .title {
  color: #555;
  background-color: #fff8;
}

.NewJob .recList {
  background-color: #f9faf9;
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
  padding: 0px;
}
.NewJob .visitor_feedback_paper {
  background-color: #f9faf9;
  margin: 0px;
  padding: 0px;
  vertical-align: middle;
}
.NewJob .visitor_feedback {
  background-color: #f9faf9;
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
}
.NewJob .visitor_feedback_paper hr {
  padding: 0px;
   margin: 0px;
}



.lastControlInLine {
  flex-grow:1;
}
.NewJob .recList .btn {
  position: relative;
  top: -13px;
   outline: none;
   border: none;
 cursor: pointer;


}
.NewJob .generalHorizontalLayout {
  background-color: #f9faf9;
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
  padding: 8px;
  vertical-align: middle;
  margin-bottom: 16px;
}
.NewJob .noHorizontalLayout {
  background-color: #f9faf9;
  padding: 8px;
  margin-bottom: 16px;
}
.NewJob .generalHorizontalLayout .layoutchild {
  margin-right: 30px;
}
.NewJob .generalHorizontalLayout .datepicker{
  width: 300px;
  min-width: 300px;
}
.listheader {
padding:0px;
  border-style: none;
}

.dateborder {
  padding: 8px;
  border-color: #ccc;
  border-width: 1px;
  height:34px;
  border-radius: 4px;
  border-style: solid;
}

.NewJob .chip {
  margin: 8px;
}

.NewJob .popoverx {
  pointer-events: none;
}
.NewJob .popover Paper {
  /*padding: 20px;
  width: 14vw;*/
}
