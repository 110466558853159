body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

.LoaderButton {
	margin-right: 8px;
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

div.landerButton {
  display: inline-block;
  vertical-align: top;
  background-color: #00000011;
  margin: 10px;
  text-align:center;
  width:250px;
  height:200px;
  cursor: pointer;
  padding:20px;
}
div.landerButton img {
  height:50px;
  margin-bottom: 20px;
}
p {
  font-family:'HelveticaNeue-Heavy', 'Arial';
}

p.desc {
  font-size:9pt;
  color: #ccc;
}
p.title {
  font-size:11pt;
  color: #777;
}
div.landerButton:hover p.title{
	color: #c64000;
}

.Home .paper {


    padding:8px 8px 8px 8px;



}

.Home .options {
  height: calc(100vh - 262px);
/*background-image:url('../images/options.png');
background-repeat:repeat;*/
  /*background-color: #efefef;
background: url("/images/ftc_logo.png");*/
}
.Home .dialog {
  height: 500px;

}
.Home .lander {
  padding: 80px 0;
  text-align: center;
  /*background-image:url('/images/ftc_logo.png');*/
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}
.Home .options h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Home .options p {
  color: #666;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

@media all and (min-width: 480px) {
  .ForgotPassword {
    padding: 60px 0;
  }

  .ForgotPassword form {
    margin: 0 auto;
    max-width: 320px;
  }
}
@media all and (min-width: 480px) {
  .Account form {
    margin: 0 auto;
    max-width: 320px;
  }
}
.Organisations .lander {
  padding: 80px 0;
  text-align: center;
}

.Organisations .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Organisations .lander p {
  color: #999;
}
.Organisations .orgs h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Organisations .orgs p {
  color: #666;
}
.Organisation form {
  padding-bottom: 15px;
}

.Organisation form textarea {
  height: 300px;
  font-size: 24px;
}

.Organisation .users h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  font-size: 24px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Organisation .users p {
  color: #666;
  line-height: 2.5;
}
.NewOrg form {
  padding-bottom: 15px;
}

.NewOrg form textarea {
  height: 300px;
  font-size: 24px;
}
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

@media all and (min-width: 480px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Signup form span.help-block {
  font-size: 14px;
  padding-bottom: 10px;
  color: #999;
}

.NewProperty form {
  padding-bottom: 15px;

}

.NewProperty form textarea {
  height: 200px;

}
.NewProperty .label_as_button {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: #ddd;
  cursor: pointer;
}

.NewProperty .label_as_button:hover {
  background-color: #efefef;

}

.Properties .lander {
  padding: 80px 0;
  text-align: center;
}

.Properties .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Properties .lander p {
  color: #999;
}
.Properties .items h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Properties .items p {
  color: #666;
}
.breadcrumb > li + li:before {
    color: #ccc;
    content: "> ";
    padding: 0 5px;
}

.PropertyKeys form {
  padding-bottom: 15px;
}

.PropertyKeys form textarea {
  height: 300px;
  font-size: 24px;
}

.PropertyKeys .versions h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  font-size: 24px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PropertyKeys .versions p {
  color: #666;
  line-height: 2;
  font-size: 20px;
}
.PropertyKeys .aaaaa span {
  color: #f00;
  line-height: 2.5;
}

.PropertyKeysHeader {
  color: #f00;
  line-height: 2.5;
}
.dialog_overflow {
  overflow: visible;
}


#propertyKeysHeaderBox {
    border-radius: 8px;
    border: 2px solid #73AD21;

    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;

}
.header {
  text-align: left;
    border-radius: 8px;
    border: 2px solid #73AD21;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
}
.header img {
  float: left;
  padding-top: 5px;
}
.clear {
  clear: both;
}




div.qrcodes {

  border: 0px solid #4CAF50;
}


table, th, td, span, p {
   border-collapse: collapse;
 padding:0px;
 margin: 0px;


 }
 td.v {

   padding:0mm 0mm 0mm 0mm;

 }
 img.ttt{
   width: 25mm;
     /*max-width: 100%;*/
     /*height: auto;*/
     /*max-height: 100%;*/
     /*max-height: calc(100% - 30px);*/
     /*margin: 0 auto;*/
     vertical-align: middle;
  /*width:25mm;*/
  padding: 0mm 2mm 0mm 0mm;
  /*border: 1px dashed grey;*/
  margin: 0mm -6mm 0mm -8mm;
  /*margin: calc((105px - 40px)/2) calc((40px - 105px)/2);*/

 }
 img.uuu{
   width: 30mm;
  /*padding: 16mm 16mm 16mm 16mm;
  border: 1px dashed grey;*/
  margin: 0mm 4mm 4mm 0mm;
  /*margin: calc((105px - 40px)/2) calc((40px - 105px)/2);*/

 }
 td.x_blank {
    border: 1px solid green;
   padding:0px;

 }
 td.x {
    border: 1px dashed grey;
   padding:0px;

 }
 tr.x_properties {
    border: 1px dashed grey;
   padding:50px;
   margin-top: 32px;

 }
td.y {
align:right;
  padding:2mm 2mm 2mm 0mm;

}
td.z {

border: 1px solid #eee;
width: 0px;
}

td.location {
padding: 5mm 0mm 0mm 0mm;
}

tr.keepTogether {
  /*page-break-inside: avoid;
  page-break-before: always;*/
}

table.a {
  table-layout: auto;
  width: 195mm;
  margin: 0 auto;


}
span.qkeyz {
  -webkit-writing-mode: vertical-lr;
          writing-mode: vertical-lr;
  /* Safari */
  -webkit-transform: rotate(270deg);

  /* Firefox */
  -moz-transform: rotate(270deg);

  /* IE */
  -ms-transform: rotate(270deg);

  /* Opera */
  -o-transform: rotate(270deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

span.keyid {
  -webkit-writing-mode: vertical-lr;
          writing-mode: vertical-lr;
  /* Safari */
  -webkit-transform: rotate(180deg);

  /* Firefox */
  -moz-transform: rotate(180deg);

  /* IE */
  -ms-transform: rotate(180deg);

  /* Opera */
  -o-transform: rotate(180deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}


table.b {
  table-layout: fixed;
  width:100%;
 }

table.c {
  table-layout: auto;
  width: 100%;
}


p.i {
  font: 14px arial, sans-serif;
  margin: 0;
  padding: 0;
  color: white !important;
  -webkit-print-color-adjust: exact;
}

p.g {
  font: 12px arial, sans-serif;
  margin: 0;
  padding: 0;
}
p.h {
  font: 10px arial, sans-serif;
white-space: nowrap;
padding: 0mm 0.5mm 0mm 0mm;
}
p.e {
  font: 14px arial, sans-serif;
}
p.f {
  font: 44px arial, sans-serif;
  padding: 16px;
}

.PropertyLog form {
  padding-bottom: 15px;
}

.PropertyLog form textarea {
  height: 300px;
  font-size: 24px;
}

.PropertyLog .versions h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  font-size: 24px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.PropertyLog .versions p {
  color: #666;
  line-height: 2;
  font-size: 20px;
}
.PropertyLog .aaaaa span {
  color: #f00;
  line-height: 2.5;
}

.PropertyLogHeader {
  color: #f00;
  line-height: 2.5;
}


#PropertyLogHeaderBox {
    border-radius: 8px;
    border: 2px solid #73AD21;

    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;

}

.NewKey form {
  padding-bottom: 15px;

}

.NewKey form textarea {
  height: 200px;

}

.Visitors .lander {
  padding: 80px 0;
  text-align: center;
}

.Visitors .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Visitors .lander p {
  color: #999;
}
.Visitors .items h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Visitors .items p {
  color: #666;
}
.breadcrumb > li + li:before {
    color: #ccc;
    content: "> ";
    padding: 0 5px;
}

.NewVisitor form {
  padding-bottom: 15px;

}

.NewVisitor form textarea {
  height: 200px;

}

.NewVisitor .input {
  display: none;
}

.Employees .lander {
  padding: 80px 0;
  text-align: center;
}

.Employees .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Employees .lander p {
  color: #999;
}
.Employees .items h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Employees .items p {
  color: #666;
}
.breadcrumb > li + li:before {
    color: #ccc;
    content: "> ";
    padding: 0 5px;
}

.NewOrgEmployee form {
  padding-bottom: 15px;

}

.NewOrgEmployee form textarea {
  height: 200px;

}
.recList {
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 16px;
}

.Observations .lander {
  padding: 80px 0;
  text-align: center;
}

.Observations .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Observations .lander p {
  color: #999;
}
.Observations .items h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Observations .items p {
  color: #666;
}
.breadcrumb > li + li:before {
    color: #ccc;
    content: "> ";
    padding: 0 5px;
}

.NewObservation form {
  padding-bottom: 50px;

}

.NewObservation form textarea {
  height: 200px;

}
.NewObservation .picgrid {

  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  overflow:hidden;
  background-color: #0000;
  height: 150px;

  /*height: 100px;*/
}
.NewObservation .picgrid img {
  object-fit: cover;
width: 100%;

}
.NewObservation .card {
  background-color: #f9faf9;


}
.NewObservation .recList img {
  object-fit: cover;
width: 150px;
height: 150px;

}
.ibcontainer {
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
  padding: 8px;
}

.ibcontainer .btn {
  position: absolute;
  top: 1;
  right: 1;
   outline: none;
   border: none;
 cursor: pointer;


}
.ibcontainer img {
  border-radius:15px;
}


.NewObservation .gridList {
  flex-wrap: nowrap;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  width: 100%;



}
.NewObservation .title {
  color: #555;
  background-color: #fff8;
}

.NewObservation .recList {
  background-color: #f9faf9;
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
  padding: 8px;
}
.NewObservation .generalHorizontalLayout {
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
}
.NewObservation .chip {
  margin: 8px;
}
.NewObservation .right_align {
  float:right;
}

.Jobs .lander {
  padding: 80px 0;
  text-align: center;
}

.Jobs .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Jobs .lander p {
  color: #999;
}
.Jobs .items h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Jobs .items p {
  color: #666;
}
.breadcrumb > li + li:before {
    color: #ccc;
    content: "> ";
    padding: 0 5px;
}

.NewJob form {
  padding-bottom: 50px;

}

.NewJob form textarea {
  height: 200px;

}
.NewJob .picgrid {

  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  overflow:hidden;
  background-color: #0000;
  height: 150px;

  /*height: 100px;*/
}
.NewJob .picgrid img {
  object-fit: cover;
width: 100%;

}
.NewJob .card {
  background-color: #f9faf9;


}
.NewJob .recList img {
  object-fit: cover;
width: 150px;
height: 150px;

}
.ibcontainer {
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
  padding: 8px;
}

.ibcontainer .btn {
  position: absolute;
  top: 1;
  right: 1;
   outline: none;
   border: none;
 cursor: pointer;


}
.ibcontainer img {
  border-radius:15px;
}


.NewJob .gridList {
  flex-wrap: nowrap;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  width: 100%;



}
.NewJob .title {
  color: #555;
  background-color: #fff8;
}

.NewJob .recList {
  background-color: #f9faf9;
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
  padding: 0px;
}
.NewJob .visitor_feedback_paper {
  background-color: #f9faf9;
  margin: 0px;
  padding: 0px;
  vertical-align: middle;
}
.NewJob .visitor_feedback {
  background-color: #f9faf9;
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
}
.NewJob .visitor_feedback_paper hr {
  padding: 0px;
   margin: 0px;
}



.lastControlInLine {
  flex-grow:1;
}
.NewJob .recList .btn {
  position: relative;
  top: -13px;
   outline: none;
   border: none;
 cursor: pointer;


}
.NewJob .generalHorizontalLayout {
  background-color: #f9faf9;
  display:flex;
  justify-content:left;
  flex-wrap: wrap;
  padding: 8px;
  vertical-align: middle;
  margin-bottom: 16px;
}
.NewJob .noHorizontalLayout {
  background-color: #f9faf9;
  padding: 8px;
  margin-bottom: 16px;
}
.NewJob .generalHorizontalLayout .layoutchild {
  margin-right: 30px;
}
.NewJob .generalHorizontalLayout .datepicker{
  width: 300px;
  min-width: 300px;
}
.listheader {
padding:0px;
  border-style: none;
}

.dateborder {
  padding: 8px;
  border-color: #ccc;
  border-width: 1px;
  height:34px;
  border-radius: 4px;
  border-style: solid;
}

.NewJob .chip {
  margin: 8px;
}

.NewJob .popoverx {
  pointer-events: none;
}
.NewJob .popover Paper {
  /*padding: 20px;
  width: 14vw;*/
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: lighter;

}
.orgBox {
	font-weight: lighter;
	display: inline-block;


	font-size: 10px;

/*
  background: #999;
  */

	color: #33ff33;
	height: 28px;
	padding-left: 4px;
	padding-right: 4px;
  padding-top: 4px;
	margin: 12px;
/*
	border: 1px solid #fff;
    border-radius: 5px;
*/

}
html {
  font-size: 16px;
}

